import React, { useState } from 'react';
import axios from 'axios';
import styles from "./printlerTest.module.css";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box } from "@mui/system";
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';


const PrintlerTest = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [result, setResult] = useState(null);
  const baseUrl = "https://static.printler.com/media/photo/"; // Replace with your base URL
  const [imageNumbers, setNumbers] = useState([]);
  const [isChecked, setTranslateQuery] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const handleCheckboxChange = (e) => {
    setTranslateQuery(!isChecked);
  };

  function generateImageUrls(baseURL, numbers) {
    return numbers.map((number) => `${baseURL}${number}_400x400.jpg`);
  }

  const clearImage = () => {
    setImageUrl("");
    setImage(null)
    document.getElementById("fileInput").value = "";
  };
  
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImage(file);
    setImageUrl(imageUrl);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      alert('No image selected');
      return;
    }

    // Convert image to base64
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const base64Image = reader.result.replace("data:", "").replace(/^.+,/, ""); // Removes the data:image/jpeg;base64, part

      // Send image to API
      //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
      const t = isChecked?1:0
      console.log(t)
      axios.post("https://stg-printler-ai.azure-api.net/v1/image/search", { image: base64Image, size:20, advice: t},
      //axios.post("http://0.0.0.0:8101/imagesearch", { image: base64Image, size:20},

             {
                headers : {'Content-Type': 'application/json',
                            'x-api-key': 'c7e8ff897c4141bd81c7707f27b1b740'}})
        .then((response) => {
          console.info(response);

          setNumbers(generateImageUrls(baseUrl, response.data.result));
        })
        .catch((error) => {
          console.error(error);
        });
    };
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: {sm: '800px', xs: '100%'} }}>
        <div className={styles.droparea} onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)}>
            <input id="fileInput" type="file" onChange={handleFileChange} />
            <p>Drag & drop an image here, or click to select one</p>
            {imageUrl && (
        <div className={styles.imagewrapper}>
          <img src={imageUrl} alt="Uploaded" className={styles.uploadedimage} />
        </div>
        )}
        </div>

      
        <Stack spacing={2} direction="row">
        <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Complement
          </label>
            <Button onClick={clearImage} variant="contained">Clear Image</Button>
            <Button onClick={handleSubmit} variant="contained">Submit</Button>
        </Stack>
      

      <Box sx={{width: 800, height: 550, overflowY: 'scroll'}}> 
        <ImageList cols={3} gap={4}>
          {imageNumbers.map((imageUrl, index) => (
            <ImageListItem sx={{width: '100%', height: 'auto'}}>
              <img
              srcSet={`${imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${imageUrl}?w=164&h=164&fit=crop&auto=format`}
              alt={`${index + 1}`}
              loading="lazy"
              />
            </ImageListItem>
            ))}
        </ImageList> 
        
      </Box>
    </Box>
  );
};

export default PrintlerTest;