import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../Navbar";
import { Container } from "@mui/material";
import { Box } from '@mui/material';
const Layout = () => {
  return (
    <>
    <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
    <Navbar />
    <Container sx={{display: 'flex', paddingTop: '64px', flexGrow: 1, overflowY: 'auto', justifyContent: 'center'}}>
      <Outlet />
    </Container>
    </Box>
    </>
  );
};

export default Layout;
