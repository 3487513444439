import React from "react";
import { useState, useRef, useEffect } from "react";
import styles from "./chatbot.module.css";
// Using ES6 import syntax
import hljs from "highlight.js";
import "highlight.js/styles/default.css"; // Import the default Highlight.js style
import { SdCardAlert } from "@mui/icons-material";
import { Box } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

const extractCodeFromMarkdown = (markdownString) => {
	return markdownString.split("```");
};

const checkIfCode = (value) => {
	const isCode =
		value.startsWith("javascript") ||
		value.startsWith("python") ||
		value.startsWith("php") ||
		value.startsWith("css") ||
		value.startsWith("csharp") ||
		value.startsWith("bash") ||
		value.startsWith("rust") ||
		value.startsWith("go") ||
		value.startsWith("typescript");
	return isCode;
};

function ChatBot() {
	const [messages, setMessages] = useState([]);
	const [input, setInput] = useState("");
	const [loading, setLoading] = useState(false);
	const scrollingDivRef = useRef(null);
	// Scroll the div to the bottom whenever its content changes
	useEffect(() => {
		if (scrollingDivRef.current) {
			scrollingDivRef.current.scrollTop =
				scrollingDivRef.current.scrollHeight;
		}
		hljs.highlightAll();
	}, [messages.length]);
	const handleSubmit = (e) => {
		e.preventDefault();

		if(input.trim() !== "") {
			setLoading(true);
			const fixedMessages = (message) => {
			if (message) {
				if (Array.isArray(message.content)) {
				const content = message.content.join('```');
				return { ...message, content: content };
				} else {
				return message;
				}
			}
				
			};

			// Send the user's message to the chatbot API
			// and add the response to the messages array
			fetch("https://dev.api.substorm.ai/ollama/ollama/api/chat", {
				method: "POST",
				body: JSON.stringify({
					model: "mistral:instruct",
					messages: messages.length>0 ? [...messages.map((m) => fixedMessages(m)), {role: "user", content: input}] : [{role: "user", content: input}],
					stream: false,
				}),
				headers: {
					"Content-Type": "application/json",
					"x-api-key": "tjXYv3s4oE1VFFbeoZtcw1YV4hBjStRM7zBhy0wx",
				}
			})
				.then((response) => response.json())
				.then((response) => {
					
					setMessages([
						...messages,
						{ role: "user", content: input },
						{
							role: "assistant",
							content: extractCodeFromMarkdown(
								response.message.content.trim()
							),
						},
					]);
				
					setInput("");
					setLoading(false);
				});
			}
		
	};
	return (

		<Box className={styles.chatbox} sx={{ width: {sm: '800px', xs: '100%'} }}>
			<div className={styles.messages} ref={scrollingDivRef}>
				{messages.map((message, index) => (
					
					<div key={index} className={`${styles.message} ${styles[message.role]}`}>
						<div className={styles.name}>
						{message.role === 'user' ? (<b>You</b>) : (<b>Assistant</b>)}
						</div>
						{Array.isArray(message.content) ? (
							message.content.map((m, mIndex) =>
								checkIfCode(m) ? (
									<pre key={mIndex}>
										<code>{m}</code>
									</pre>
								) : (
									<span key={mIndex}>{m}</span>
								)
							)
						) : (
							<span>{message.content}</span>
						)}
					</div>
				))}
			</div>
			
      
		<form onSubmit={handleSubmit}> {/* Wrap input and button inside form */}
			<Box sx={{display: "flex", pt: "10px", pb: "20px"}}> 
						<TextField 
							color="primary"
							label="Message..."
							id="outlined-basic" 
							variant="outlined" 
							value={input}
							onChange={(e) => setInput(e.target.value)}
							fullWidth={true}
							onKeyDown={(e) => {
								if(e.key === "Enter" && !e.shiftKey) {
									e.preventDefault();
									handleSubmit(e);
							
								}
							}}
							
							multiline
							maxRows={8}
						/>
				
				<Box sx={{ alignSelf: 'flex-end'}}>
					<Button
						type="submit"
						variant="contained"
						style = {{ height: 56}}
						disabled={!input.trim()}
						>
						
						{loading ? <CircularProgress color="inherit"/> : <PlayArrow />}
					</Button>
					</Box>
				</Box>
				
		</form>

		</Box>
   
	);
}
export default ChatBot;